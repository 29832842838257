<template>
  <div class="form-step">
    <div class="details-left">
      Billing Details
    </div>
    <div class="details-right">
      {{step}}/{{steps}}
    </div>
    <div class="title-box">
      <div class="section-title">
        Enter Your Billing Details
      </div>
      <p v-if="step !== 3" class="section-paragraph">
        We will charge your card a $25 deposit to hold your appointment, this will be applied towards any co-pays or out of pocket obligations you may have after your claim has process or after your appointment is completed.
      </p>
      <p v-else class="section-paragraph">
        Please enter your card details for us to keep on file. We will not charge your card without your permission.
      </p>
    </div>
    <div v-if="cardError == false" class="form-fields">
      <label>Card Number</label>
      <div id="card-number" class="text-field"></div>
      <label>Card Expiry</label>
      <div id="card-expiry" class="text-field"></div>
      <label>Card CVC</label>
      <div id="card-cvc" class="text-field"></div>
      <!-- <div id="card-error"></div>-->
    </div>
    <a v-if="loading == false && cardError == false" class="btn-next" @click="createToken">
      Submit
    </a>
    <div v-if="loading == true" class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
  </div>
</template>

<script>
// import qs from 'qs';
import axios from 'axios';
export default {
  name: 'StepFive',
  props: {
    step: Number,
    steps: Number,
    data: Object
  },
  data () {
    return {
      errors: [],
      formData: this.data,
      token: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      cardError: false,
      loading: false
    }
  },
  computed: {
    stripeElements () {
      return this.$stripe.elements();
    },
  },
  mounted () {
    // Style Object documentation here: https://stripe.com/docs/js/appendix/style
    const style = {
      base: {
        backgroundColor: '#eaebf0',
        color: '#000',
        fontSize: '20px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    };
    this.cardNumber = this.stripeElements.create('cardNumber', { style });
    this.cardNumber.mount('#card-number');
    this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
    this.cardExpiry.mount('#card-expiry');
    this.cardCvc = this.stripeElements.create('cardCvc', { style });
    this.cardCvc.mount('#card-cvc');
  },
  beforeDestroy () {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  },
  watch: {
    cardNumber(newNumber) {
      console.log(newNumber)
    }
  },
  methods: {
    updateData() {
      this.formData.billingDetails.cardNumber = this.cardNumber
      this.formData.billingDetails.cardExpiry = this.cardExpiry
      this.formData.billingDetails.cardCvc = this.cardCvc
      this.$emit('updateData', this.formData)
    },
    formatter(value) {
      var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    async createToken () {
      this.errors = [];
      this.$emit('throwErrors', this.errors)
      this.loading = true;
      const { token, error } = await this.$stripe.createToken(this.cardNumber);
      if (error) {
        console.log(error.message)
        if(error.message) {
          this.errors.push(error.message);
        } else {
          this.errors.push('Your card information is incomplete.');
        }
        this.loading = false;
        this.$emit('throwErrors', this.errors)
        // handle error here
        //document.getElementById('card-error').innerHTML = error.message;
        return;
      } else {
        this.tokenHandler(token)
      }
      console.log(token);
      console.log(this.$stripe);
      console.log(this.cardNumber)
      // handle the token
      // send it to your server
    },
    async tokenHandler(token) {
      const url = `${this.$apiUrl}/pay`
      const customer = {
        name: this.formData.fullName,
        email: this.formData.email,
        phone: this.formData.phone,
        metadata: {
          date_of_birth: this.formData.dob
        },
        address: {
          line1: this.formData.address.line1,
          line2: this.formData.address.line2,
          city: this.formData.address.city,
          state: this.formData.address.state,
          country: this.formData.address.country,
          postal_code: this.formData.address.zip
        },
      }
      const data = { 'stripeToken': token, 'customer': customer };
      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        data: data,
        url,
      };
      axios(options)
        .then((response) => {
          this.loading = false
          console.log(response.data)
          this.formData.paymentStatus = 'none'
          this.getFrontImgUrl()
          this.$emit('nextStep')
        })
        .catch((error) => {
          this.loading = false
          this.cardError = true
          this.formData.paymentStatus = 'failed'
          this.errors.push(error.message);
          this.getFrontImgUrl()
          this.$emit('throwErrors', this.errors)
          console.log(error)
        });
    },
    getFrontImgUrl () {
      if(this.formData.insuranceFront == null) {
        this.formData.imgFront = ""
        this.getBackImgUrl()
      } else {
        const url = 'https://www.filestackapi.com/api/store/S3?key=AcCHF6bL5TPuLBQjOW7wSz'
        const options = {
          method: 'POST',
          headers: { 'content-type': this.formData.insuranceFront.type ||'img/png' },
          data: this.formData.insuranceFront,
          url,
        };
        axios(options)
          .then((response) => {
            console.log(response.data)
            this.formData.imgFront = response.data.url
            this.getBackImgUrl()
          })
          .catch((error) => {
            console.log(error)
            this.formData.imgFront = ""
            this.getBackImgUrl()
          })
      }
    },
    getBackImgUrl () {
      if(this.formData.insuranceBack == null) {
        this.formData.imgBack = ""
        this.submitData()
      } else {
        const url = 'https://www.filestackapi.com/api/store/S3?key=AcCHF6bL5TPuLBQjOW7wSz'
        const options = {
          method: 'POST',
          headers: { 'content-type': this.formData.insuranceBack.type ||'img/png' },
          data: this.formData.insuranceBack,
          url,
        };
        axios(options)
          .then((response) => {
            console.log(response.data)
            this.formData.imgBack = response.data.url
            this.submitData()
          })
          .catch((error) => {
            console.log(error)
            this.formData.imgBack = ""
            this.submitData()
          })
      }
    },
    submitData() {
      const url = `${this.$apiUrl}/submit`
      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        data: this.formData,
        url,
      };
      axios(options)
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>
.details-left {
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 15px;
  margin-left: 15px;
  color: #40805b;
  font-size: 18px;
}
.details-right {
  position: absolute;
  top: 0;
  right: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  margin-right: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: rgba(32,38,44,.38);
  font-size: 18px;
}
.title-box {
  display: flex;
  padding-top: 100px;
  margin-bottom: 35px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}
.section-title {
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 12px;
  font-size: 50px;
  font-weight: 600;
}
.section-paragraph {
  max-width: 500px;
  font-weight: 400;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0;
  margin-bottom: 10px;
}
.form-fields {
  max-width: 550px;
  display: flex;
  width: 100%;
  margin-right: auto;
  margin-bottom: 5px;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  align-items: flex-start;
  margin-top: 70px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}
.text-field {
  height: auto;
  margin-bottom: 0;
  padding: 20px 30px;
  border: 1px #000;
  border-radius: 33px;
  background-color: #eaebf0;
  color: #000;
  font-size: 20px;
  display: block;
  width: 100%;
  line-height: 1.42857143;
  margin: 0;
}
.btn-next{
    margin-left: auto;
    display: inline-block;
    min-width: 100px;
    padding: 20px 30px;
    border-radius: 40px;
    background-color: #ff5666;
    background-position: -300px 50%;
    background-size: auto 100%;
    background-repeat: no-repeat;
    -webkit-transition: background-color .2s,background-position .5s;
    transition: background-color .2s,background-position .5s;
    font-family: Futurapt,sans-serif;
    color: #fff;
    font-size: 15px;
    line-height: 1.65;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    position: absolute;
    bottom: 18px;
    right: 28px;
}

.lds-ellipsis {
  margin-left: auto;
  display: inline-block;
  position: absolute;
  bottom: 18px;
  right: 28px;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #ff5666;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
@media (max-width: 780px) {
  .section-title {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 12px;
    font-size: 32px;
    font-weight: 600;
  }
}

</style>