<template>
  <div class="form-step">
    <div class="title-box">
      <div class="section-title">
        Thank you!
      </div>
      <p class="section-paragraph">
        We look forward to providing you with resources, treatment options and clinical support no matter where you are in your journey.
      </p>
    </div>
    <a class="btn-next" href="https://www.pelexmed.com/">
      Back to Home Page
    </a>
  </div>
</template>

<script>
export default {
  name: 'StepOne',
  props: {
    step: Number,
    steps: Number,
    data: Object
  },
  data () {
    return {
      formData: this.data
    }
  }
}
</script>

<style scoped>
.details-left {
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 15px;
  margin-left: 15px;
  color: #40805b;
  font-size: 18px;
}
.details-right {
  position: absolute;
  top: 0;
  right: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  margin-right: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: rgba(32,38,44,.38);
  font-size: 18px;
}
.title-box {
  display: flex;
  padding-top: 100px;
  margin-bottom: 35px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}
.section-title {
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 12px;
  font-size: 50px;
  font-weight: 600;
}
.section-paragraph {
  max-width: 500px;
  font-weight: 400;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0;
  margin-bottom: 10px;
}
.form-fields {
  max-width: 550px;
  display: flex;
  width: 100%;
  margin-right: auto;
  margin-bottom: 5px;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  align-items: flex-start;
  margin-top: 70px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}
.text-field {
  height: auto;
  margin-bottom: 0;
  padding: 20px 30px;
  border: 1px #000;
  border-radius: 33px;
  background-color: #eaebf0;
  color: #000;
  font-size: 20px;
  display: block;
  width: 100%;
  line-height: 1.42857143;
  margin: 0;
}
.btn-next{
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    min-width: 100px;
    padding: 20px 30px;
    border-radius: 40px;
    background-color: #ff5666;
    background-position: -300px 50%;
    background-size: auto 100%;
    background-repeat: no-repeat;
    -webkit-transition: background-color .2s,background-position .5s;
    transition: background-color .2s,background-position .5s;
    font-family: Futurapt,sans-serif;
    color: #fff;
    font-size: 15px;
    line-height: 1.65;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
}
@media (max-width: 780px) {
  .section-title {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 12px;
    font-size: 32px;
    font-weight: 600;
  }
}
</style>