import Vue from 'vue'
import Router from 'vue-router'
import FormWrapper from './../components/form-wrapper.vue'
import ComprehensiveConsultation from './../components/comprehensive-consultation.vue'
import Schedule from './../components/schedule.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    { path: '/', component: FormWrapper },
    { path: '/comprehensive-consultation', component: ComprehensiveConsultation },
    { path: '/schedule', component: Schedule}
  ]
})