<template>
  <div class="form-wrapper">
    
    <img :src="bgImg" loading="lazy" alt="" class="schedule-page-img">
    <div class="form-block">
      <b-form class="signup-form">
        <div v-if="currentStep === 0" class="form-content">
          <patient-type :step="currentStep" :steps="totalSteps" :data="formData" @updateData="updateData" />
        </div>
        <div v-if="currentStep === 1" class="form-content">
          <step-one :step="currentStep" :steps="totalSteps" :data="formData" @updateData="updateData" />
        </div>
        <div v-if="currentStep === 2" class="form-content">
          <step-two :step="currentStep" :steps="totalSteps" :data="formData" @updateData="updateData" />
        </div>
        <div v-if="currentStep === 3" class="form-content">
          <step-three :step="currentStep" :steps="totalSteps" :data="formData" @updateData="updateData" />
        </div>
        <div v-if="currentStep === 4" class="form-content">
          <step-four :step="currentStep" :steps="totalSteps" :data="formData" @updateData="updateData" @noInsurance="noInsurance"/>
        </div>
        <div v-if="currentStep === 5" class="form-content">
          <step-five :step="currentStep" :steps="totalSteps" :data="formData" @updateData="updateData" @throwErrors="throwErrors" @nextStep="nextStep" />
        </div>
        <div v-if="currentStep === 6" class="form-content">
          <step-six v-if="loadSix" :step="currentStep" :steps="totalSteps" :data="formData" :appointment="appointment" @updateData="updateData" />
        </div>

        <b-alert :show="errors.length > 0" :fade="true" variant="danger">
          <h4 class="alert-heading">Please correct the following error(s):</h4>
          <p v-for="(error, index) in errors" :key="index">{{ error }}</p>
        </b-alert>

        <div class="btn-wrapper">
          <a v-if="currentStep !== 0" class="btn-back" @click="prevStep()">
            <b-icon icon="arrow-left"></b-icon>
            previous
          </a>
          <a v-if="currentStep != 5 && currentStep != 6" class="btn-next" @click="nextStep()">
            Next
          </a>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import Background from "../assets/images/template-hero-bg.svg"
import StepOne from ".//form-steps/step-one"
import StepTwo from ".//form-steps/step-two"
import StepThree from ".//form-steps/step-three"
import StepFour from ".//form-steps/step-four"
import StepFive from ".//form-steps/step-five"
import StepSix from ".//form-steps/step-six"
import PatientType from ".//form-steps/patient-type"

export default {
  name: 'FormWrapper',
  components: {
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    StepFive,
    StepSix,
    PatientType
  },
  data () {
    return {
      loadSix: false,
      parameters: {},
      appointment: '20min',
      errors: [],
      bgImg: Background,
      totalSteps: 6,
      currentStep: 0,
      formData: {
        existingPatient: '',
        page: '',
        fullName: '',
        email: '',
        phone: '',
        dob: '',
        fullAddress: '',
        address: {
          line1: '',
          line2: '',
          city: '',
          state: '',
          country: '',
          zip: ''
        },
        insuranceFront: null,
        insuranceBack: null,
        billingDetails: {
          cardNumber: '',
          cardExpiry: '',
          cardCvc: ''
        }
      }
    }
  },
  async created() {
    this.parameters = await this.getParameters()

    if(this.parameters['page']) {
      this.formData.page = this.parameters['page']
    }

    if(this.parameters['appointment']) {
      this.appointment = this.parameters['appointment']
    } else {
      this.appointment = '20min'
    }
    this.loadSix = true
    console.log(this.appointment)
  },
  methods: {
    getParameters() {
      let uri = window.location.href.split('?');
    
      if (uri.length == 2) {
        let vars = uri[1].split('&');
        let urlVars = {};
        let tmp = '';
        vars.forEach(function(v) {
          tmp = v.split('=');
          if(tmp.length == 2)
            urlVars[tmp[0]] = tmp[1];
        });
        return urlVars;
      } else {
        return {
          appointment: null
        }
      }
    },
    updateData(data) {
      this.formData = data
    },
    throwErrors(errors) {
      this.errors = errors
    },
    noInsurance() {
      this.errors = [];
      this.currentStep += 1
    },
    nextStep() {
      this.errors = [];
      const data = this.formData
      switch(this.currentStep) {
        case 0:
          if (data.existingPatient === 'no') {
            this.currentStep += 1
            return
          } else if (data.existingPatient === 'yes') {
            this.appointment = 'followup'
            this.currentStep = 6
            return
          } else {
            this.errors.push('Please make a selection.')
          }
          break
        case 1:
          if (data.fullName != '' & this.validateEmail(data.email) != '' & this.validatePhone(data.phone)) {
            this.currentStep += 1
            return
          }
          if (!data.fullName) {
            this.errors.push('Name required.');
          }
          if (!data.email) {
            this.errors.push('Email required.');
          } else if (!this.validateEmail(data.email)) {
            this.errors.push('Email is not valid.');
          }
          if (!data.phone) {
            this.errors.push('Phone required.');
          } else if (!this.validatePhone(data.phone)) {
            this.errors.push('Phone is not valid.');
          }
          break
        case 2:
          if (data.dob != '') {
            this.currentStep += 1
            return
          }
          if (!data.dob) {
            this.errors.push('Date of Birth required.');
          }
          break
        case 3:
          if (data.address != '') {
            this.currentStep += 1
            return
          }
          if (!data.address) {
            this.errors.push('Address required.');
          }
          break
        case 4:
          if (data.insuranceFront != null & data.insuranceBack != null) {
            this.currentStep += 1
            return
          }
          if (!data.insuranceFront) {
            this.errors.push('Insurance card front image required.');
          }
          if (!data.insuranceBack) {
            this.errors.push('Insurance card back image required.');
          }
          break
        case 5:
          if (this.formData.page === 'Mira' && this.appointment === '20min') {
            this.appointment = 'Mira20min'
          }
          if (this.formData.page === 'Mira' && this.appointment === '45min') {
            this.appointment = 'Mira45min'
          }
          this.currentStep += 1
          break
      }
    },
    prevStep() {
      if(this.appointment == 'followup') {
        this.currentStep = 0
      } else {
        this.currentStep -= 1
      }
    },
    validateEmail(email) {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true
      } else {
        return false
      }
    },
    validatePhone(phone) {
      if (phone.length == 14) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-wrapper {
  display: flex;
  min-height: 100vh;
  padding: 90px 3% 100px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  align-items: flex-start;
}
.schedule-page-img {
  position: absolute;
  top: 0;
  right: 72%;
  z-index: 0;
  width: 35%;
  max-width: 960px;
  border: 0;
  vertical-align: middle;
  display: inline-block;
}
.form-block {
  max-width: 100%;
  position: relative;
  padding: 20px;
  background-color: #fff;
  margin-bottom: 0;
  border: 2px solid #fff;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: inset 0 30px 100px 0 rgb(60 60 60 / 10%), 30px 0 100px 0 rgb(60 60 60 / 20%);
  margin: 0 0 15px;
}
.signup-form {
  max-width: 100%;
  min-width: 0;
  height: 100%;
  background-color: transparent;
  position: relative;
  text-align: center;
  clear: both;
  -webkit-tap-highlight-color: transparent;
}
.signup-form .alert {
  width: 550px;
  margin: auto;
  border-radius: 20px;
}
.signup-form .alert p {
  margin-bottom: 3px;
}
.form-content {
  padding-bottom: 30px;
}
.btn-next{
    margin-left: auto;
    display: inline-block;
    min-width: 100px;
    padding: 20px 30px;
    border-radius: 40px;
    background-color: #ff5666;
    background-position: -300px 50%;
    background-size: auto 100%;
    background-repeat: no-repeat;
    -webkit-transition: background-color .2s,background-position .5s;
    transition: background-color .2s,background-position .5s;
    font-family: Futurapt,sans-serif;
    color: #fff;
    font-size: 15px;
    line-height: 1.65;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
}
.btn-back {
    padding-bottom: 5px;
    padding-left: 10px;
    color: rgba(60,60,60,.4);
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
}
.btn-wrapper {
  display: flex;
  padding: 30px 28px 28px 28px;
}

</style>
